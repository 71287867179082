export class PageLink {
    id;
    title;
    slug;

    constructor(id,title, slug) {
        this.id = id;
        this.title = title;
        this.slug = slug;
    }
}